import * as React from 'react'

interface Props {
  theme?: "white" | "black"
}

export default function EmailIcon({theme}: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <g fill="none" fillRule="evenodd">
          <g>
              <g>
                  <g>
                      <path d="M0 0H14V14H0z" transform="translate(-1257 -1158) translate(0 1086) translate(1257 72)"/>
                      <path stroke={theme === "white" ? "#fff" : "#888"} d="M10 3.5c.414 0 .79.168 1.06.44.272.27.44.646.44 1.06h0v4c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0-6c-.414 0-.79-.168-1.06-.44-.272-.27-.44-.646-.44-1.06h0V5c0-.414.168-.79.44-1.06.27-.272.646-.44 1.06-.44h0z" transform="translate(-1257 -1158) translate(0 1086) translate(1257 72)"/>
                      <path stroke={theme === "white" ? "#fff" : "#888"} d="M2 4.5L7 7.5 12 4.5" transform="translate(-1257 -1158) translate(0 1086) translate(1257 72)"/>
                  </g>
              </g>
          </g>
      </g>
    </svg>

  )
}