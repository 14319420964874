import * as React from 'react';
import Title from "../components/title/Title";

type ContentType = {
  image: string
  title: string
  description: string
  content: string[]
}

interface Props {
  content: ContentType
}

export default function ProductCard({ content }: Props): JSX.Element {
  return (
    <div className="product-group">
      <img src={content.image} alt={`${content.title} image`} width="600" height="300"/>
      <div className="product-content-group">
        <Title size="large" color="black" className="montserrat-font" style={{marginBottom: "20px"}}>
          {content.title}
        </Title>
        <p className="landing-description">
          {content.description}
        </p>
        {content.content.map((item, i) => (
        <p className="landing-content" key={i}>{item}</p>))}
      </div>
    </div>
  );
}
