import * as React from 'react';
import Messages from '../utils/Messages';
import Splash from '../components/Splash';
import ProductList from '../landing/ProductList';
import DemoSection from '../landing/DemoSection';
import PlanList from '../landing/PlanList';
import ClientSection from '../landing/ClientSection';
import ContactSection from '../landing/ContactSection';
import Footer from '../components/Footer';
import LayoutProvider from '../components/layout/LayoutProvider';

const MobileLandingTopImg = process.env.GATSBY_IMAGE_PUBLIC_URL + "MobileLandingTopImg.png"
const LandingTopImg = process.env.GATSBY_IMAGE_PUBLIC_URL + "LandingTopImg.png"

interface Props {
  location: any;
}

class LandingContainer extends React.Component<Props> {
  public render(): JSX.Element {
    return (
      <LayoutProvider location={this.props.location} className="landing-container" render={(innerWidth) => 
        <>
          <div className="app-body">
            {innerWidth <= 1000 ? 
              <Splash title={Messages.LANDING_CONTENTS.mobileTitle} content={Messages.LANDING_CONTENTS.mobileContent} image={MobileLandingTopImg} />
              : <Splash title={Messages.LANDING_CONTENTS.title} content={Messages.LANDING_CONTENTS.content} image={LandingTopImg} />}
            <ProductList />
            <DemoSection />
            <PlanList />
            <ClientSection />
            <ContactSection />
          </div>
          <Footer theme="white"/>
        </>
      } />
    )
  }
}

export default LandingContainer