import * as React from 'react';
import Title from "../components/title/Title";

type ContentType = {
  image: string
  title: string
  content: string
}

interface Props {
  content: ContentType
}

export default function PlanCard({ content }: Props): JSX.Element {
  return (
    <div className="card plan-group">
      <img src={content.image} alt={`${content.title} image`} width="60" height="60"/>
      <div className="plan-content-group text-center">
        <Title size="large" color="black" className="montserrat-font">
          {content.title}
        </Title>
        <p className="landing-content">
          {content.content}
        </p>
      </div>
    </div>
  );
}
