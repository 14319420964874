import * as React from 'react';
import { useCallback } from 'react'
import { navigate } from 'gatsby-link'
import Title from "../components/title/Title";
import PlanCard from "./PlanCard";
import { Messages } from '../utils';
import { ArrowRight } from '../components/images/svg';

export default function PlanList(): JSX.Element {
  const clickPlan = useCallback(() => {
    navigate("/plan")
  }, [])
  return (
    <section className="plan-container">
      <div>
        <Title color="black" size="xlarge" className="montserrat-font text-center" >
          Plan
        </Title>
        <p className="landing-description text-center" >{Messages.LANDING_CONTENTS.palnDesc}</p>
        <button className="plan-button" onClick={clickPlan}>Learn more<ArrowRight className="orange-svg-icon"/></button>
        <div className="plan-section landing-pc-width">
          {Messages.LANDING_CONTENTS.planContents.map((content) => (
            <PlanCard key={content.title} content={content} />
          ))}
        </div>
      </div>
    </section>
  );
}
