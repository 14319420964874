import * as React from 'react'

interface Props {
  theme?: "white" | "black"
}

export default function AddrIcon({theme}: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g transform="translate(-835 -1158) translate(0 1086) translate(835 72)">
                        <path d="M0 0H14V14H0z"/>
                        <path stroke={theme === "white" ? "#fff" : "#888"} d="M7 2.5c.966 0 1.841.392 2.475 1.025C10.108 4.16 10.5 5.034 10.5 6c0 .921-.592 2.13-1.296 3.186C8.422 10.36 7.475 11.5 7 11.5c-.48 0-1.422-1.134-2.201-2.304C4.093 8.138 3.5 6.924 3.5 6c0-.966.392-1.841 1.025-2.475C5.16 2.892 6.034 2.5 7 2.5z"/>
                        <circle cx="7" cy="6" r="1" fill={theme === "white" ? "#fff" : "#888"}/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}