import * as React from 'react';
import { useCallback } from 'react'
import Button from "../components/button/Button";
import { Messages } from '../utils';
import { ArrowRight } from '../components/images/svg';
import { navigate } from 'gatsby-link';

export default function DemoSection(): JSX.Element {
  const clickDemo = useCallback(() => {
    navigate("/demo")
  }, [])
  return (
    <section className="demo-section">
      <div className="landing-pc-width">
        <div className="demo-content landing-pc-width">
          <p className="demo-desciption">
            {Messages.LANDING_CONTENTS.demoDesc}
          </p>
          <p className="demo-content">
            {Messages.LANDING_CONTENTS.demoContent}
          </p>
          <Button size="xxlarge" color="orange" onClick={clickDemo} style={{borderRadius: "40px"}}>Demo Start<ArrowRight /></Button>
        </div>
      </div>
    </section>
  );
}
