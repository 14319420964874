import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react'
import Title from '../components/title/Title';
import { Config, Messages } from '../utils';
import { isClient } from '../utils/browser';

export default function ClientSection(): JSX.Element {
  const [clients, setClients] = useState(Messages.LANDING_CONTENTS.clientLogos);

  useEffect(() => {
    async function getClients() {
      if(isClient) {
        axios.get(Config.CLIENT_LOGOS_URL).then((res) => {
          setClients(res.data.clients)
        }).catch(() => {
        })
      }
    }

    getClients();
  }, [isClient])
  
  return (
    <section className="client-section">
      <div className="client-content landing-pc-width">
        <Title color="black" size="xlarge" className="montserrat-font">
          Clients
        </Title>
        <div className="client-group landing-pc-width">
          {clients.map((content, i) => (
            <img key={i} src={`${process.env.GATSBY_S3_PUBLIC_URL}${content.Key}`} alt={`client_logo_${i}`} width="190" height="84"/>
          ))}
        </div>
      </div>
    </section>
  );
}
