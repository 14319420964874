import * as React from 'react';
import Title from "../components/title/Title";
import { Messages } from '../utils';
import ProductCard from "./ProductCard";

export default function ProductList(): JSX.Element {
  return (
    <section>
      <div>
        <Title color="black" size="xlarge" className="montserrat-font text-center">
          What we do
        </Title>
        <div className="product-section landing-pc-width">
          {Messages.LANDING_CONTENTS.productContents.map((content) => (
            <ProductCard key={content.title} content={content} />
          ))}
        </div>
      </div>
    </section>
  );
}
