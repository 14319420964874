import * as React from 'react'

interface Props {
  className?: string
}
export default function ArrowRight({ className }: Props): JSX.Element {
  return (
    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h30v30H0z" />
        <path
          d="m22.113 9.21.094.083 6.5 6.5c.029.028.055.059.08.09l-.08-.09a1.008 1.008 0 0 1 .292.675L29 16.5v.033l-.004.052L29 16.5a1.008 1.008 0 0 1-.213.617c-.05.063-.107.12-.17.17l.09-.08a1.008 1.008 0 0 1-.59.286l-.032.003a1.006 1.006 0 0 1-.052.003L28 17.5H2a1 1 0 0 1-.117-1.993L2 15.5h23.585l-4.792-4.793a1 1 0 0 1 1.32-1.497z"
          fill="#FFF"
          fillRule="nonzero"
          className={className}
        />
      </g>
    </svg>
  )
}
