import * as React from 'react'

interface Props {
  title: string,
  content: string,
  image: any,
  [key: string]: any;
}

export default function Splash({
  title,
  content,
  image
}: Props): JSX.Element {
  const planImage = {
    background: `url(${image}) center center / cover no-repeat`,
  }

  return ( 
    <div className="splash">
      <div className="splash-group landing-pc-width"> 
        <div className="splash-content">
          <p className="title">{title}</p>
          <p>{content}</p>
          <div className="splash-image" style={planImage} /> 
        </div>
      </div>
    </div>
  )
}