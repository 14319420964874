import * as React from 'react'

interface Props {
  theme?: "white" | "black"
}

export default function ContactIcon({theme}: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fillRule="evenodd">
            <g>
                <g>
                    <g transform="translate(-1409 -1158) translate(0 1086) translate(1409 72)">
                        <path d="M0 0H14V14H0z"/>
                        <path stroke={theme === "white" ? "#fff" : "#888"} d="M10 3.5c.414 0 .79.168 1.06.44.272.27.44.646.44 1.06h0v4c0 .414-.168.79-.44 1.06-.27.272-.646.44-1.06.44h0-6c-.414 0-.79-.168-1.06-.44-.272-.27-.44-.646-.44-1.06h0V5c0-.414.168-.79.44-1.06.27-.272.646-.44 1.06-.44h0z" transform="rotate(90 7 7)"/>
                        <rect width="2" height="1" x="6" y="9" fill={theme === "white" ? "#fff" : "#888"} rx=".5"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}