import * as React from 'react';
import { useCallback } from 'react'
import Button from "../components/button/Button";
import { ArrowRight } from '../components/images/svg';
import Title from "../components/title/Title";
import { Config, Messages } from '../utils';
import { openNewPage } from '../utils/browser';

export default function ContactSection(): JSX.Element {
  const clickContact = useCallback(() => {
    openNewPage(`mailto:${Config.SUPPORT_EMAIL}`);
  }, [])
  return (
    <section className="contact-section">
      <div className="contact-content">
        <Title color="black" size="xlarge" className="montserrat-font">
          Contact
        </Title>
        <p className="landing-description" style={{marginBottom: "35px"}}>{Messages.LANDING_CONTENTS.contactDesc}</p>
        <Button size="xxlarge" color="orange" onClick={clickContact} style={{borderRadius: "40px"}}>Talk to sales<ArrowRight /></Button>
      </div>
    </section>
  );
}
