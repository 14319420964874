import * as React from 'react'

export default function LogoText(): JSX.Element {
  return (
    <svg width="159" height="36" viewBox="0 0 159 36" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path id="a" d="M0 0h17.59v20.094H0z" />
        <path id="c" d="M0 0h41.76v36H0z" />
      </defs>
      <g fill="none" fillRule="evenodd" opacity=".4">
        <path
          fill="#000"
          d="m58.59 12.086-.676 3.428h8.237L65.135 20.6h-8.208l-1.27 6.4H49l3.95-20H69l-1.016 5.086zM71.353 7H78l-4.353 20H67zM80.24 10.281c-.586-.557-.878-1.257-.878-2.099 0-.97.334-1.774 1.003-2.415.67-.64 1.54-.96 2.61-.96.97 0 1.748.26 2.334.782.585.521.878 1.203.878 2.044 0 1.043-.33 1.885-.991 2.525-.661.64-1.535.96-2.622.96-.97 0-1.749-.279-2.334-.837zm-1.328 2.045h6.165l-2.987 15.01h-6.164l2.986-15.01zM96.57 21.793c.502-.622.753-1.455.753-2.497 0-.714-.197-1.285-.59-1.715-.392-.43-.936-.645-1.63-.645-.803 0-1.456.31-1.958.933-.503.622-.754 1.454-.754 2.497 0 .713.196 1.285.59 1.715.392.43.935.644 1.63.644.803 0 1.456-.31 1.958-.932m3.822-8.918c.95.548 1.722 1.349 2.315 2.4.594 1.053.89 2.292.89 3.719 0 1.61-.36 3.073-1.082 4.39-.721 1.317-1.694 2.35-2.917 3.1a7.463 7.463 0 0 1-3.973 1.126c-2.01 0-3.434-.622-4.274-1.866l-1.397 6.915h-6.192l4.055-20.333h5.836l-.275 1.372c1.133-1.098 2.484-1.647 4.055-1.647 1.023 0 2.01.275 2.96.824M112.834 12.792l6.287.003 1.153-5.585-7.512-.003c-1.811 0-2.74.246-3.679.975l-.022.018c-1.033.816-1.637 2.011-1.957 3.87l-3.041 15.217 5.854.002 1.435-7.092 5.998.002 1.124-5.497-5.97-.002.33-1.908zM121.795 9.496l-3.752 17.797h1.88a4.362 4.362 0 0 0 4.273-3.488l3.571-17.404-2.299-.001a3.728 3.728 0 0 0-3.673 3.096M136.807 19.767c-.22 1.274-1.51 2.392-2.762 2.392-.572 0-1.057-.203-1.367-.571-.324-.387-.445-.937-.34-1.55.23-1.334 1.485-2.42 2.798-2.42.531 0 .995.204 1.306.574.34.405.47.965.365 1.575m4.033-5.187c-1.221-1.453-3.02-2.254-5.067-2.254h-.004c-4.172 0-8.14 3.323-8.846 7.407-.35 2.025.133 3.943 1.359 5.402 1.238 1.473 3.071 2.286 5.164 2.286 1.99 0 4.003-.76 5.665-2.141 1.665-1.384 2.767-3.213 3.101-5.15.362-2.095-.126-4.066-1.372-5.55"
        />
        <g transform="translate(141.41 12.54)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M12.063 7.261c-.242 1.217-1.439 2.18-2.669 2.148-.512-.013-.945-.21-1.22-.555-.295-.37-.395-.888-.282-1.458.23-1.154 1.502-2.178 2.67-2.148.49.013.915.215 1.196.568.306.384.415.897.305 1.445m4.205-4.916C15.107.888 13.342.056 11.295.002c-2.22-.057-4.552.89-6.237 2.53-1.65 1.58-2.212 2.768-2.866 6.051L0 20.093h5.361l1.185-5.941c.797.349 1.556.518 2.405.54 3.827.099 7.716-3.114 8.494-7.016.396-1.989-.022-3.882-1.177-5.331"
            fill="#000"
            mask="url(#b)"
          />
        </g>
        <g>
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            d="m41.085 11.482-1.632.505-2.222.718a.51.51 0 0 1-.667-.47C36.251 2.81 28.283-2.225 18.44.953c-9.29 3-17.012 12.269-18.235 21.433 0 0-.108.626-.201 1.564a.524.524 0 0 0 .672.569l1.632-.505 2.222-.718a.51.51 0 0 1 .667.47c.313 9.426 8.281 14.461 18.125 11.283 9.29-3 17.011-12.269 18.235-21.433 0 0 .109-.626.201-1.564a.524.524 0 0 0-.672-.569"
            fill="#000"
            mask="url(#d)"
          />
        </g>
        <path d="m21.085 18.657 1.397-5.988a1.24 1.24 0 0 1 1.888-.757l4.388 2.885 4.074 2.678c.799.525.73 1.719-.123 2.148l-5.751 2.893-5.306 2.67c-.94.472-2.003-.367-1.764-1.393l1.197-5.136z" fill="#FFF" />
        <path d="m12.775 19.464 1.396-5.988a1.24 1.24 0 0 1 1.888-.756l4.388 2.884 4.074 2.678c.8.525.73 1.719-.123 2.148l-5.751 2.893-5.306 2.67c-.94.472-2.003-.367-1.764-1.393l1.198-5.136z" fill="#FFF" />
      </g>
    </svg>
  )
}
